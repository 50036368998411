import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["space", "background"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './Spacer.style';
var StyleSection = styled.section.withConfig({
  componentId: "sc-m7qb5d-0"
})(["", ";"], styles);
var Spacer = function Spacer(_ref) {
  var space = _ref.space,
    background = _ref.background,
    others = _objectWithoutProperties(_ref, _excluded);
  var className = "nva-content-spacer content-spacer-".concat(space === null || space === undefined ? 'none nva-content-spacer--none' : "".concat(space.toLowerCase(), " nva-content-spacer--").concat(space.toLowerCase()));
  return __jsx(StyleSection, {
    className: className,
    background: background
  });
};
Spacer.defaultProps = {
  space: 'None'
};
export default styled(Spacer).withConfig({
  componentId: "sc-m7qb5d-1"
})(["", ";"], styles);
export { Spacer as SpacerVanilla };