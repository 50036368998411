import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import { imageRenditions } from './config';
import { getBackgroundStyle } from '../../../utils/utilityFunction';
import textures from '../../../themes/textures';
var backgroundStyles = '';
var styles = css(["", ";&.five-card-nva.organism{padding-bottom:80px;.fiftyFiftyText{position:relative;}.slick_anchor{position:relative;}.slick-prev,.slick-next{top:", "px;transform:translateY(-50%);@media (min-width:", "){top:50%;}}.slick-dots{bottom:-45px;}.slick_para{h4:hover{color:var(--sc-5-card--text-color-hover,", ");}}.fiftyFiftyImage{&.image{height:calc((", " * (100vw ", " - 80px) + 40px));@media (min-width:", "){height:calc((", " * (100vw ", " - 268px) + 40px));}@media (min-width:", "){height:calc((", " * (57.233vw ", " - 80px) + 80px));}@media (min-width:", "){height:calc((", " * (57.233vw ", " - 80px) + 80px));}@media (min-width:", "){height:calc(", "px + 80px);}}}.fifty-fifty-card{padding-bottom:0;padding-top:20px;}.textCard{padding:30px;@media (min-width:", "){padding:20px 30px;}}.variation-a{.slick_para{position:absolute;bottom:0;background-color:var(--sc-5-card--bg-color-para,", ");width:80%;}.slick_header{margin:17px 15px;}.ImageLinkContainer{text-align:center;}.imageWrapper{width:280px;@media (min-width:", "){width:180px;}@media (min-width:", "){width:198px;}}.slick_image{margin:0;}}}"], function (props) {
  // if (props.fiveCardNvaData && props.fiveCardNvaData.overrideBackground) {
  if (props.fiveCardNvaData && props.fiveCardNvaData.background) {
    var _props$theme, _props$theme$fiveCard, _props$theme2;
    var background = props.fiveCardNvaData.background;
    var overrideId = background ? background.backgroundId : '';
    var backgroundImageURLs = background.imageBackground ? {
      desktopImage: background.imageBackground && background.imageBackground.url,
      mobileImage: background.imageBackground && background.imageBackground.url
    } : background.imageBackground && background.imageBackground.url ? {
      desktopImage: background.imageBackground && background.imageBackground.url,
      mobileImage: background.imageBackground && background.imageBackground.url
    } : '';
    backgroundStyles = getBackgroundStyle(overrideId, backgroundImageURLs, background && background.colorBackground || ((_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : (_props$theme$fiveCard = _props$theme.fiveCard) === null || _props$theme$fiveCard === void 0 ? void 0 : _props$theme$fiveCard.bgColor), (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.palette, textures);
    return backgroundStyles;
  }
}, Number(imageRenditions.tabletP.h) / 2, breakPoints.desktop, function (props) {
  var _props$theme3, _props$theme3$fiveCar;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : (_props$theme3$fiveCar = _props$theme3.fiveCard) === null || _props$theme3$fiveCar === void 0 ? void 0 : _props$theme3$fiveCar.hoverColor;
}, imageRenditions.mobile.h / imageRenditions.mobile.w, function (props) {
  return props.scrollbarWidth ? "- ".concat(props.scrollbarWidth, "px") : '';
}, breakPoints.tabletP, imageRenditions.tabletP.h / imageRenditions.tabletP.w, function (props) {
  return props.scrollbarWidth ? "- ".concat(props.scrollbarWidth, "px") : '';
}, breakPoints.desktop, imageRenditions.tabletL.h / imageRenditions.tabletL.w, function (props) {
  return props.scrollbarWidth ? "- ".concat(props.scrollbarWidth, "px") : '';
}, breakPoints.desktopM, imageRenditions.desktop.h / imageRenditions.desktop.w, function (props) {
  return props.scrollbarWidth ? "- ".concat(props.scrollbarWidth, "px") : '';
}, breakPoints.desktopL, imageRenditions.desktopL.h, breakPoints.desktop, function (props) {
  var _props$theme4, _props$theme4$fiveCar;
  return (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : (_props$theme4$fiveCar = _props$theme4.fiveCard) === null || _props$theme4$fiveCar === void 0 ? void 0 : _props$theme4$fiveCar.bgColorPara;
}, breakPoints.desktop, breakPoints.desktopM);
export default styles;