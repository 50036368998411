export var imageRenditions = {
  imageRenditions: {
    desktopL: {
      w: '1600',
      h: '438'
    },
    desktop: {
      w: '1366',
      h: '400'
    },
    tabletL: {
      w: '1024',
      h: '400'
    },
    tabletP: {
      w: '768',
      h: '210'
    },
    mobile: {
      w: '320',
      h: '210'
    }
  }
};