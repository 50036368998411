export var imageRenditions = {
  desktopL: {
    h: '531',
    w: '836'
  },
  desktop: {
    h: '480',
    w: '782'
  },
  tabletL: {
    h: '480',
    w: '584'
  },
  tabletP: {
    h: '200',
    w: '540'
  },
  mobile: {
    h: '200',
    w: '375'
  }
};