import { css } from 'styled-components';
import { setCssVar } from '../../../utils';
import SEARCH_BLADE_VAR from './SearchBlade.variables';
var searchInputWidth = SEARCH_BLADE_VAR.searchInputWidth,
  paddingBlock = SEARCH_BLADE_VAR.paddingBlock,
  bgColor = SEARCH_BLADE_VAR.bgColor,
  resultsGap = SEARCH_BLADE_VAR.resultsGap,
  statsColor = SEARCH_BLADE_VAR.statsColor,
  errorColor = SEARCH_BLADE_VAR.errorColor;
var styles = css(["&.nva-search-blade{", " ", " background-color:var(", ");padding-block:var(", ");}.nva-search__input{", "}.nva-search-blade__results-stats{", " color:var(", ");}.nva-search-blade__error-message{", " color:var(", ");}.nva-search-blade__results{", " display:flex;flex-direction:column;gap:var(", ");}"], setCssVar(bgColor, bgColor.value), setCssVar(paddingBlock, paddingBlock.value), bgColor["private"], paddingBlock["private"], setCssVar(searchInputWidth, searchInputWidth.value), setCssVar(statsColor, statsColor.value), statsColor["private"], setCssVar(errorColor, errorColor.value), errorColor["private"], setCssVar(resultsGap, resultsGap.value), resultsGap["private"]);
export default styles;