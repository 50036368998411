import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "blogArticleListData", "currentPage", "slugifiedTitle", "categoryValues", "selectedValue", "categoryFilter", "limit", "pageRoute", "categoriesRoute", "pageUrl", "anchorLink"];
var __jsx = React.createElement;
/**
 * Represents BlogArticle.
 * @author kkum
 */import React from 'react';
import BlogList from '../../molecules/BlogList';
import { imageRenditions, imageRenditionsFeatured } from './config';
import { routes } from '../../../constants/routes';
var BlogArticleListComp = function BlogArticleListComp(_ref) {
  var className = _ref.className,
    blogArticleListData = _ref.blogArticleListData,
    currentPage = _ref.currentPage,
    slugifiedTitle = _ref.slugifiedTitle,
    categoryValues = _ref.categoryValues,
    selectedValue = _ref.selectedValue,
    categoryFilter = _ref.categoryFilter,
    limit = _ref.limit,
    pageRoute = _ref.pageRoute,
    categoriesRoute = _ref.categoriesRoute,
    pageUrl = _ref.pageUrl,
    anchorLink = _ref.anchorLink,
    others = _objectWithoutProperties(_ref, _excluded);
  if (!blogArticleListData) {
    return null;
  }
  return __jsx("div", {
    className: "nva-blog-article-list ".concat(className),
    id: anchorLink
  }, __jsx(BlogList, {
    blogArticleListData: blogArticleListData,
    currentPage: currentPage,
    slugifiedTitle: slugifiedTitle,
    categoryValues: categoryValues,
    selectedValue: selectedValue,
    categoryFilter: categoryFilter,
    limit: limit,
    imageRenditions: imageRenditions,
    imageRenditionsFeatured: imageRenditionsFeatured,
    pageRoute: pageRoute,
    categoriesRoute: categoriesRoute,
    pageUrl: pageUrl
  }));
};
BlogArticleListComp.defaultProps = {
  className: '',
  pageRoute: routes.blog,
  categoriesRoute: routes.blogCategories,
  anchorLink: ''
};
export default BlogArticleListComp;