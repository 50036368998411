import _defineProperty from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import React from "react";
var __jsx = React.createElement;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { StyledTables, StyledTableWrapper, StyledTable, StyledTableRow, StyledTableDiv, StyledTableHeading } from './TableEditor.styles.js';
function Cell(_ref) {
  var col = _ref.col,
    globals = _ref.globals;
  var isHeading = (col === null || col === void 0 ? void 0 : col.cellType) === 'th';
  var props = {
    className: "nva-table-editor__col ".concat(isHeading ? 'nva-table-editor__col--heading' : 'nva-table-editor__col--division'),
    'data-bold': col !== null && col !== void 0 && col.bold ? col === null || col === void 0 ? void 0 : col.bold : null,
    'data-italic': col !== null && col !== void 0 && col.italic ? col === null || col === void 0 ? void 0 : col.italic : null,
    'data-color': col !== null && col !== void 0 && col.color ? col === null || col === void 0 ? void 0 : col.color : null,
    'data-background-color': col !== null && col !== void 0 && col.backgroundColor ? col === null || col === void 0 ? void 0 : col.backgroundColor : null,
    'data-border-color': col !== null && col !== void 0 && col.borderColor ? col === null || col === void 0 ? void 0 : col.borderColor : null,
    'data-border-size': globals.borderSize ? globals.borderSize : null,
    'data-border-b-color': col !== null && col !== void 0 && col.borderBColor ? col === null || col === void 0 ? void 0 : col.borderBColor : null,
    'data-border-l-color': col !== null && col !== void 0 && col.borderLColor ? col === null || col === void 0 ? void 0 : col.borderLColor : null,
    'data-border-r-color': col !== null && col !== void 0 && col.borderRColor ? col === null || col === void 0 ? void 0 : col.borderRColor : null,
    'data-border-t-color': col !== null && col !== void 0 && col.borderTColor ? col === null || col === void 0 ? void 0 : col.borderTColor : null,
    'data-align': col !== null && col !== void 0 && col.align ? col === null || col === void 0 ? void 0 : col.align : null,
    'data-v-align': col !== null && col !== void 0 && col.vAlign ? col === null || col === void 0 ? void 0 : col.vAlign : null,
    'data-col-width': col !== null && col !== void 0 && col.colWidth ? col === null || col === void 0 ? void 0 : col.colWidth : null,
    'data-font-size': col !== null && col !== void 0 && col.fontSize ? col === null || col === void 0 ? void 0 : col.fontSize : null,
    'data-upper-case': col !== null && col !== void 0 && col.upperCase ? col === null || col === void 0 ? void 0 : col.upperCase : null,
    'data-g-padding': globals.padding ? globals.padding : null,
    'data-padding-top': col !== null && col !== void 0 && col.paddingTop ? col === null || col === void 0 ? void 0 : col.paddingTop : null,
    'data-padding-right': col !== null && col !== void 0 && col.paddingRight ? col === null || col === void 0 ? void 0 : col.paddingRight : null,
    'data-padding-bottom': col !== null && col !== void 0 && col.paddingBottom ? col === null || col === void 0 ? void 0 : col.paddingBottom : null,
    'data-padding-left': col !== null && col !== void 0 && col.paddingLeft ? col === null || col === void 0 ? void 0 : col.paddingLeft : null
  };
  if (isHeading) {
    return __jsx(StyledTableHeading, props, col === null || col === void 0 ? void 0 : col.val);
  }
  return __jsx(StyledTableDiv, props, col === null || col === void 0 ? void 0 : col.val);
}
function TableWrapper(_ref2) {
  var _rows$, _rows, _head$columns, _foot$columns;
  var table = _ref2.table;
  var rows = table.rows,
    globals = table.globals;
  var head = ((_rows$ = rows[0]) === null || _rows$ === void 0 ? void 0 : _rows$.group) === 'head' ? JSON.parse(JSON.stringify(rows[0])) : null;
  var foot = ((_rows = rows[rows.length - 1]) === null || _rows === void 0 ? void 0 : _rows.group) === 'foot' ? _objectSpread({}, rows[rows.length - 1]) : null;
  var body = rows.filter(function (row) {
    return (row === null || row === void 0 ? void 0 : row.group) !== 'head' && (row === null || row === void 0 ? void 0 : row.group) !== 'foot';
  });
  return __jsx(StyledTableWrapper, {
    className: "nva-table-editor__wrapper",
    breakPoint: globals.breakPoint
  }, __jsx(StyledTable, {
    tableWidth: globals === null || globals === void 0 ? void 0 : globals.tableWidth,
    tableBorderColor: globals === null || globals === void 0 ? void 0 : globals.borderColor,
    className: "nva-table-editor__table"
  }, head && __jsx("thead", {
    className: "nva-table-editor__head"
  }, __jsx(StyledTableRow, {
    className: "nva-table-editor__row"
  }, (_head$columns = head.columns) === null || _head$columns === void 0 ? void 0 : _head$columns.map(function (col, colIndex) {
    return __jsx(Cell, {
      key: colIndex,
      col: col,
      globals: globals
    }, col.val);
  }))), __jsx("tbody", {
    className: "nva-table-editor__body"
  }, body === null || body === void 0 ? void 0 : body.map(function (row, index) {
    var _row$columns;
    return __jsx(StyledTableRow, {
      key: index,
      className: "nva-table-editor__row"
    }, row === null || row === void 0 ? void 0 : (_row$columns = row.columns) === null || _row$columns === void 0 ? void 0 : _row$columns.map(function (col, colIndex) {
      return __jsx(Cell, {
        key: colIndex,
        col: col,
        globals: globals
      }, col === null || col === void 0 ? void 0 : col.val);
    }));
  })), foot && __jsx("tfoot", {
    className: "nva-table-editor__foot"
  }, __jsx(StyledTableRow, {
    className: "nva-table-editor__row"
  }, (_foot$columns = foot.columns) === null || _foot$columns === void 0 ? void 0 : _foot$columns.map(function (col, colIndex) {
    return __jsx(Cell, {
      key: colIndex,
      col: col,
      globals: globals
    }, col.val);
  })))));
}
export default function TableEditor(_ref3) {
  var tables = _ref3.tables,
    anchorLink = _ref3.anchorLink;
  return __jsx(React.Fragment, null, (tables === null || tables === void 0 ? void 0 : tables.length) > 0 && __jsx(StyledTables, {
    className: "nva-table-editor",
    id: anchorLink
  }, tables === null || tables === void 0 ? void 0 : tables.map(function (table, index) {
    return __jsx(TableWrapper, {
      key: index,
      table: table
    });
  })));
}