import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import { imageRenditions } from './config';
export default css(["width:100%;height:auto;position:relative;overflow:hidden;background-color:var(--sc-text-card--hero-bg-color,", ");.tile-image{margin:0;overflow:hidden;height:calc((100vw ", ") * ", ");@media (min-width:", "){height:calc((100vw ", ") * ", ");}@media (min-width:", "){height:calc((100vw ", ") * ", ");}@media (min-width:", "){height:calc((100vw ", ") * ", ");}@media (min-width:", "){max-height:", "px;height:calc((100vw ", ") * ", ");}}.tile-heading{text-align:center;margin:0 auto;padding:25px 20px 20px;color:var(--sc-text-card--text-color-dark,", ");@media (min-width:", "){padding:0;position:absolute;bottom:40px;left:50%;transform:translateX(-50%);&.dark{color:var(--sc-text-card--text-color-dark,", ");text-shadow:0px 0px 4px rgba(255,255,255,1),0px 0px 4px rgba(255,255,255,1),0px 0px 4px rgba(255,255,255,1),0px 0px 4px rgba(255,255,255,1),0px 0px 4px rgba(255,255,255,1);}&.light{color:var(--sc-text-card--text-color-light,", ");text-shadow:0px 0px 4px rgba(0,0,0,0.5),0px 0px 4px rgba(0,0,0,0.5),0px 0px 4px rgba(0,0,0,0.5),0px 0px 4px rgba(0,0,0,0.5),0px 0px 4px rgba(0,0,0,0.5);}}@media (min-width:", "){padding:40px 20px;}}"], function (props) {
  var _props$theme;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.card.bgColorHero;
}, function (props) {
  return props.scrollbarWidth ? "- ".concat(props.scrollbarWidth, "px") : '';
}, imageRenditions.imageRenditions.mobile.h / imageRenditions.imageRenditions.mobile.w, breakPoints.tabletP, function (props) {
  return props.scrollbarWidth ? "- ".concat(props.scrollbarWidth, "px") : '';
}, imageRenditions.imageRenditions.tabletP.h / imageRenditions.imageRenditions.tabletP.w, breakPoints.desktop, function (props) {
  return props.scrollbarWidth ? "- ".concat(props.scrollbarWidth, "px") : '';
}, imageRenditions.imageRenditions.tabletL.h / imageRenditions.imageRenditions.tabletL.w, breakPoints.desktopM, function (props) {
  return props.scrollbarWidth ? "- ".concat(props.scrollbarWidth, "px") : '';
}, imageRenditions.imageRenditions.desktop.h / imageRenditions.imageRenditions.desktop.w, breakPoints.desktopL, imageRenditions.imageRenditions.desktopL.h, function (props) {
  return props.scrollbarWidth ? "- ".concat(props.scrollbarWidth, "px") : '';
}, imageRenditions.imageRenditions.desktopL.h / imageRenditions.imageRenditions.desktopL.w, function (props) {
  var _props$theme2;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.card.textColorDark;
}, breakPoints.desktop, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.card.textColorDark;
}, function (props) {
  var _props$theme4;
  return (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.card.textColorLight;
}, breakPoints.tabletP);