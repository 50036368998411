export var imageRenditions = {
  desktopL: {
    w: '394',
    h: '302'
  },
  desktop: {
    w: '337',
    h: '257'
  },
  tabletL: {
    w: '304',
    h: '232'
  },
  tabletP: {
    w: '540',
    h: '390'
  },
  mobile: {
    w: '280',
    h: '202'
  }
};
export var imageRenditionsFeatured = {
  desktopL: {
    w: '1254',
    h: '614'
  },
  desktop: {
    w: '1070',
    h: '524'
  },
  tabletL: {
    w: '964',
    h: '524'
  },
  tabletP: {
    w: '540',
    h: '294'
  },
  mobile: {
    w: '280',
    h: '152'
  }
};