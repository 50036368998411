import styled from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
export var StyledTables = styled.section.withConfig({
  componentId: "sc-r669ak-0"
})(["width:95%;font-family:inherit;line-height:inherit;letter-spacing:inherit;margin:2rem auto 0;@media (min-width:", "){width:80%;}"], breakPoints.desktop);
export var StyledTableWrapper = styled.div.withConfig({
  componentId: "sc-r669ak-1"
})(["flex-direction:column;align-items:center;", ""], function (props) {
  switch (props.breakPoint) {
    case 'desktop':
      return "\n          display: none;\n          @media (min-width: ".concat(breakPoints.tabletL, ") {\n            display: flex;\n          }\n        ");
    case 'mobile':
      return "\n          display: flex;\n          @media (min-width: ".concat(breakPoints.tabletL, ") {\n            display: none;\n          }\n        ");
    default:
      return "\n          display: flex;\n        ";
  }
});
export var StyledTable = styled.table.withConfig({
  componentId: "sc-r669ak-2"
})(["display:flex;flex-direction:column;font-family:inherit;width:", ";border:", ";margin-bottom:2rem;"], function (_ref) {
  var tableWidth = _ref.tableWidth;
  return tableWidth ? tableWidth + '%' : '100%';
}, function (_ref2) {
  var tableBorderColor = _ref2.tableBorderColor;
  return tableBorderColor ? '1px solid ' + tableBorderColor : '';
});
export var StyledTableRow = styled.tr.withConfig({
  componentId: "sc-r669ak-3"
})(["display:flex;width:100%;"]);
function getCellStyles(_ref3) {
  var colWidth = _ref3['data-col-width'],
    bold = _ref3['data-bold'],
    italic = _ref3['data-italic'],
    color = _ref3['data-color'],
    backgroundColor = _ref3['data-background-color'],
    borderColor = _ref3['data-border-color'],
    borderSize = _ref3['data-border-size'],
    borderBColor = _ref3['data-border-b-color'],
    borderLColor = _ref3['data-border-l-color'],
    borderRColor = _ref3['data-border-r-color'],
    borderTColor = _ref3['data-border-t-color'],
    align = _ref3['data-align'],
    vAlign = _ref3['data-v-align'],
    fontSize = _ref3['data-font-size'],
    upperCase = _ref3['data-upper-case'],
    gPadding = _ref3['data-g-padding'],
    paddingLeft = _ref3['data-padding-left'],
    paddingTop = _ref3['data-padding-top'],
    paddingRight = _ref3['data-padding-right'],
    paddingBottom = _ref3['data-padding-bottom'];
  return "\n  width: ".concat(colWidth ? colWidth + '%' : '100%', ";\n  display: flex;\n  font-weight: ").concat(bold ? 'bold' : '', ";\n  font-style: ").concat(italic ? 'italic' : '', ";\n  color: ").concat(color ? color : '', ";\n  background-color: ").concat(backgroundColor ? backgroundColor : '', ";\n  border: ").concat(borderColor ? 'solid ' + borderColor : '', ";\n  // override any retroactively set boarders\n  border-bottom: ").concat(borderBColor ? borderSize + 'px solid ' + borderBColor : '', ";\n  border-left: ").concat(borderLColor ? borderSize + 'px solid ' + borderLColor : '', ";\n  border-right: ").concat(borderRColor ? borderSize + 'px solid ' + borderRColor : '', ";\n  border-top: ").concat(borderTColor ? borderSize + 'px solid ' + borderTColor : '', ";\n  justify-content: ").concat(align ? align : '', ";\n  text-align: ").concat(align ? align : '', ";\n  align-items: ").concat(vAlign ? vAlign : '', "; // Registering but not totally working - block\n  font-size: ").concat(fontSize ? fontSize + 'rem' : '', ";\n  text-transform: ").concat(upperCase ? 'uppercase' : '', ";\n  padding-left: ").concat(paddingLeft ? "".concat(paddingLeft, "rem") : "".concat(gPadding, "rem"), ";\n  padding-top: ").concat(paddingTop ? "".concat(paddingTop, "rem") : "".concat(gPadding, "rem"), ";\n  padding-right: ").concat(paddingRight ? "".concat(paddingRight, "rem") : "".concat(gPadding, "rem"), ";\n  padding-bottom: ").concat(paddingBottom ? "".concat(paddingBottom, "rem") : "".concat(gPadding, "rem"), ";\n  overflow-wrap: anywhere;\n  ");
}
export var StyledTableDiv = styled.td.withConfig({
  componentId: "sc-r669ak-4"
})(["", ""], function (props) {
  return getCellStyles(props);
});
export var StyledTableHeading = styled.th.withConfig({
  componentId: "sc-r669ak-5"
})(["", ""], function (props) {
  return getCellStyles(props);
});