import SEARCH_VAR from '../../atoms/Search/Search.variables';
var SEARCH_BLADE_VAR = {
  searchInputWidth: {
    "private": SEARCH_VAR.width["private"],
    "public": '--nva-search-blade--search-input-width',
    value: '100%'
  },
  paddingBlock: {
    "private": '--_nva-search-blade--padding-block',
    "public": '--nva-search-blade--padding-block',
    value: 'var(--g-spacing-block-section)'
  },
  bgColor: {
    "private": '--_nva-search-blade--bg-color',
    "public": '--nva-search-blade--bg-color',
    value: 'hsl(var(--g-color-white))'
  },
  resultsGap: {
    "private": '--_nva-search-blade--results-gap',
    "public": '--nva-search-blade--results-gap',
    value: 'var(--g-spacing-5)'
  },
  statsColor: {
    "private": '--_nva-search-blade--stats-color',
    "public": '--nva-search-blade--stats-color',
    value: 'hsl(var(--g-color-neutral-600))'
  },
  errorColor: {
    "private": '--_nva-search-blade--error-color',
    "public": '--nva-search-blade--error-color',
    value: 'hsl(var(--g-color-error))'
  }
};
export default SEARCH_BLADE_VAR;