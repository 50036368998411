var __jsx = React.createElement;
/**
 * Represents ThreeColumnTextBlock Component.
 * @author Harmeet
 */import React from 'react';
import styled from 'styled-components';
import styles from './ThreeColumnText.style';
import RichText from '../../atoms/RichText/RichText';
import Heading from '../../atoms/Heading';
import urlSlug from 'url-slug';
var ThreeColumnTextBlock = styled.section.withConfig({
  componentId: "sc-1suc4ci-0"
})(["", ";"], styles);
var ThreeColumnText = function ThreeColumnText(_ref) {
  var threeColumnTextData = _ref.threeColumnTextData;
  if (!threeColumnTextData) {
    return null;
  }
  var heading = threeColumnTextData.heading,
    anchorLink = threeColumnTextData.anchorLink,
    textBlocksCollection = threeColumnTextData.textBlocksCollection,
    topPadding = threeColumnTextData.topPadding,
    bottomPadding = threeColumnTextData.bottomPadding;
  var className = "organism row threeColumnText";

  // top padding from contentful
  className += topPadding === null || topPadding === 'Default' || topPadding === undefined ? '' : " padding-top-override-".concat(topPadding.toLowerCase());

  // bottom padding from contentful
  className += bottomPadding === null || bottomPadding === 'Default' || bottomPadding === undefined ? '' : " padding-bottom-override-".concat(bottomPadding.toLowerCase());
  var textBlocksArray = textBlocksCollection && textBlocksCollection.items;
  if (!textBlocksArray) {
    textBlocksArray = threeColumnTextData.textBlocks;
  }
  var idVal = anchorLink ? urlSlug(anchorLink) : '';
  return __jsx(ThreeColumnTextBlock, {
    styleType: "threeColumnTextBlock",
    props: threeColumnTextData,
    className: className,
    id: idVal
  }, __jsx("div", {
    className: "container"
  }, __jsx("div", {
    className: "block block0 col-xs-12"
  }, __jsx(Heading, {
    HeadingType: "h2"
  }, heading)), __jsx("div", {
    className: "row"
  }, textBlocksArray && textBlocksArray.map(function (block, i) {
    var content = block.body && block.body.json;
    if (!content) {
      content = block.body && block.body.body;
    }
    return __jsx("div", {
      key: i,
      className: "block col-xs-12 col-md-4"
    }, __jsx(RichText, {
      jsonContent: content
    }));
  }))));
};
export default ThreeColumnText;