import { css } from 'styled-components';
import textures from '../../../themes/textures';
import { getBackgroundStyle } from '../../../utils/utilityFunction';
import breakPoints from '../../../themes/breakpoints';
var backgroundStyles = '';
var styles = css(["", ";width:100%;&.content-spacer{&-large{height:60px;@media (min-width:", "){height:110px;}}&-medium{height:40px;@media (min-width:", "){height:80px;}}&-small{height:25px;@media (min-width:", "){height:50px;}}&-none{height:0;}}"], function (props) {
  if (props.background) {
    var _props$theme, _props$theme2;
    var background = props.background;
    var backgroundImageURLs = background && background.imageBackground ? {
      desktopImage: background.imageBackground && background.imageBackground.url,
      mobileImage: background.imageBackground && background.imageBackground.url
    } : '';
    var overrideId = background ? background.backgroundId : '';
    backgroundStyles = getBackgroundStyle(overrideId, backgroundImageURLs, background && background.colorBackground || ((_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.accordion.bodyBackgroundColor), (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.palette, textures);
    return backgroundStyles;
  }
}, breakPoints.desktop, breakPoints.desktop, breakPoints.desktop);
export default styles;