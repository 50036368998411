import React from "react";
var __jsx = React.createElement;
import { useState } from 'react';
import { useSwiper } from 'swiper/react';
function SliderControls(_ref) {
  var _swiper$wrapperEl, _swiper$wrapperEl2;
  var heroCarouselData = _ref.heroCarouselData;
  var swiper = useSwiper();
  // Swiper is not triggering a re-render when pause is toggled, so we use an internal state for this
  var _useState = useState(swiper.originalParams.autoplay.enabled),
    isRunning = _useState[0],
    setIsRunning = _useState[1];
  var handlePrev = function handlePrev() {
    swiper.slidePrev();
  };
  var handleNext = function handleNext() {
    swiper.slideNext();
  };
  var handlePagination = function handlePagination(index) {
    // TODO: use slideToLoop() if loop is enabled
    swiper.slideTo(index);
  };
  var handlePause = function handlePause() {
    if (isRunning) {
      swiper.autoplay.stop();
      setIsRunning(false);
    } else {
      swiper.autoplay.start();
      setIsRunning(true);
    }
  };
  return __jsx("div", {
    className: "l-grid nva-slider__controls",
    role: "group",
    "aria-label": "Slide controls"
  }, __jsx("div", {
    className: "l-col-full nva-slider__controls-inner"
  }, __jsx("button", {
    className: "nva-slider__navigation nva-slider__navigation--prev",
    "aria-label": "Previous slide",
    onClick: handlePrev,
    "aria-controls": (_swiper$wrapperEl = swiper.wrapperEl) === null || _swiper$wrapperEl === void 0 ? void 0 : _swiper$wrapperEl.id
  }, __jsx("svg", {
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    height: 24,
    width: 24
  }, __jsx("path", {
    fill: "currentColor",
    d: "M17.77 3.77 16 2 6 12l10 10 1.77-1.77L9.54 12z"
  }))), __jsx("div", {
    className: "nva-slider__pagination"
  }, heroCarouselData === null || heroCarouselData === void 0 ? void 0 : heroCarouselData.cardsCollection.items.map(function (item, index) {
    return __jsx("button", {
      key: index,
      className: "nva-slider__pagination-bullet-button",
      onClick: function onClick() {
        return handlePagination(index);
      },
      "aria-label": "Go to slide ".concat(index + 1)
      // TODO: use realIndex if loop is enabled
      ,
      "aria-current": swiper.activeIndex === index ? 'true' : null
    }, __jsx("span", {
      className: "nva-slider__pagination-bullet"
    }, __jsx("span", {
      className: "nva-slider__pagination-bullet-inner"
    })));
  })), __jsx("button", {
    className: "nva-slider__navigation nva-slider__navigation--next",
    "aria-label": "Next slide",
    onClick: handleNext,
    "aria-controls": (_swiper$wrapperEl2 = swiper.wrapperEl) === null || _swiper$wrapperEl2 === void 0 ? void 0 : _swiper$wrapperEl2.id
  }, __jsx("svg", {
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    height: 24,
    width: 24
  }, __jsx("path", {
    fill: "currentColor",
    d: "M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z"
  }))), __jsx("button", {
    className: "nva-slider__pause",
    "aria-label": "Pause carousel",
    "aria-pressed": isRunning ? 'false' : 'true',
    onClick: handlePause
  }, __jsx("svg", {
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    height: 48,
    width: 48
  }, __jsx("path", {
    fill: "currentColor",
    d: isRunning ? 'M6 19h4V5H6zm8-14v14h4V5z' : 'M8 5v14l11-7z'
  })))));
}
export default SliderControls;